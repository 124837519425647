import { useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Link } from 'react-router-dom';
import {
  Loader,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  ActionIcon,
  Menu,
  Skeleton,
  rem,
} from '@mantine/core';
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
  IconDots,
  IconEye,
  IconTrash,
  IconFileX,
  IconFileCheck,
  IconEdit,
  IconDownload,
  IconSend,
} from '@tabler/icons-react';
import ConfirmationModal from '@components/ConfirmationModal/ConfirmationModal';
import { NoFoliosModal } from '@components/NoFoliosModal/NoFoliosModal';
// import DocumentDrawer from '@components/DocumentDrawer';
import NoPdfModal from '@pages/authenticated/documents/NoPdfModal';
import { VoidDocumentModal } from '@components/VoidDocumentModal/VoidDocumentModal';
import DocumentPrevisualizationModal from '@pages/authenticated/documents/previsualization/modal';
import styles from './TableSort.module.scss';
import { CommonHash, Caf } from '@types';
import AnswerDocumentModal from '@components/AnswerDocumentModal/AnswerDocumentModal';
import { ResendPDFModal } from '@components/ResendPDFModal/ResendPDFModal';
import { useEmitDocumentMutation, useDeleteDocument } from '@api/documents';
import { capitalizeFirstLetter } from '@utils';
import { useCafs } from '@api/cafs';
import { BadgeWithHoverCard } from '@components/BadgeWithHoverCard/BadgeWithHoverCard';

interface RowData {
  company: string;
  dte_type: string;
  number: number;
  total_amount: number;
  issue_date: string;
  sii_status: string;
}

interface ThProps {
  children: React.ReactNode;
  direction: string;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, direction, sorted, onSort }: ThProps) {
  const Icon = sorted ? (direction === 'ASC' ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <Table.Th className={styles.th}>
      <UnstyledButton onClick={onSort} className={styles.control}>
        <Group justify="space-between">
          <Text fw={800} fz="sm">
            {children}
          </Text>
          <Center className={styles.icon}>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}

interface DocsProps {
  docsProps: any;
  isFetching: boolean;
  sortKey: string;
  kind: string;
  direction: string;
  setSortKey: React.Dispatch<React.SetStateAction<string>>;
  setDirection: React.Dispatch<React.SetStateAction<string>>;
}

const formatAmount = (number: number) => {
  if (typeof number === 'number') {
    return '$' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  } else {
    return number;
  }
};


export function TableSort({
  docsProps,
  isFetching,
  kind,
  sortKey,
  direction,
  setDirection,
  setSortKey,
}: DocsProps) {
  const [sortedData, setSortedData] = useState(docsProps);
  const { data: cafs } = useCafs();

  useEffect(() => {
    setSortedData(docsProps);
  }, [docsProps]);

  const setSorting = (field: keyof RowData) => {
    // const reversed = field === sortBy ? !reverseSortDirection : false;
    setSortKey(field);
    setDirection(direction === 'ASC' ? 'DESC' : 'ASC');
  };


  let rows;
  if (isFetching || sortedData === undefined) {
    rows = Array.from({ length: 5 }, (_, index) => (
      <Table.Tr key={index}>
        <Table.Td>
          <Skeleton visible={isFetching} height={rem(30)} />
        </Table.Td>
        <Table.Td>
          <Skeleton visible={isFetching} height={rem(30)} />
        </Table.Td>
        <Table.Td>
          <Skeleton visible={isFetching} height={rem(30)} />
        </Table.Td>
        <Table.Td>
          <Skeleton visible={isFetching} height={rem(30)} />
        </Table.Td>
        <Table.Td>
          <Skeleton visible={isFetching} height={rem(30)} />
        </Table.Td>
        <Table.Td>
          <Skeleton visible={isFetching} height={rem(30)} />
        </Table.Td>
        <Table.Td>
          <Skeleton visible={isFetching} height={rem(30)} />
        </Table.Td>
        <Table.Td>
          <Skeleton visible={isFetching} height={rem(30)} />
        </Table.Td>
      </Table.Tr>
    ));
  } else {
    rows = sortedData.map((row: any) => (
      <TableRow row={row} kind={kind} key={row.uuid} cafs={cafs?.data} />
    ));
  }

  return (
    <ScrollArea>
      <Table horizontalSpacing="md" verticalSpacing="md" miw={700} highlightOnHover withTableBorder>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>RUT</Table.Th>
            <Th
              sorted={sortKey === 'company'}
              direction={direction}
              onSort={() => setSorting('company')}
            >
              Razón Social
            </Th>
            <Table.Th>Tipo de documento</Table.Th>
            <Th
              sorted={sortKey === 'number'}
              direction={direction}
              onSort={() => setSorting('number')}
            >
              Folio
            </Th>
            <Th
              sorted={sortKey === 'total_amount'}
              direction={direction}
              onSort={() => setSorting('total_amount')}
            >
              Monto
            </Th>
            <Table.Th>Estado</Table.Th>
            <Th
              sorted={sortKey === 'issue_date'}
              direction={direction}
              onSort={() => setSorting('issue_date')}
            >
              Fecha de Emisión
            </Th>
            <Table.Th>Acciones</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <Table.Tr>
              <Table.Td colSpan={docsProps && docsProps[0] ? Object.keys(docsProps[0])?.length : 0}>
                <Text fw={500} ta="center">
                  No hay documentos
                </Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>

      </Table>
    </ScrollArea>
  );
}

const TableRow = ({
  row,
  kind,
  cafs,
}: {
  row: CommonHash;
  kind: string;
  cafs?: Caf[];
}): React.ReactElement => {
  const [openedCreditNoteModal, { open: openCreditNoteModal, close: closeCreditNoteModal }] =
    useDisclosure(false);
  const [
    openedAnswerDocumentModal,
    { open: openAnswerDocumentModal, close: closeAnswerDocumentModal },
  ] = useDisclosure(false);
  const [openedResendPDFModal, { open: openResendPDFModal, close: closeResendPDFModal }] =
    useDisclosure(false);
  const [openedNoPdfModal, { open: openNoPdfModal, close: closeNoPdfModal }] = useDisclosure(false);
  const [acceptDocument, setAcceptDocument] = useState(true);
  const canEmit = () => {
    const currentCaf = cafs?.find(
      (caf) => capitalizeFirstLetter(caf.document) == capitalizeFirstLetter(row.dte_type),
    );
    if (currentCaf !== undefined && currentCaf.left_to_use > 0) {
      return true;
    }
    return false;
  };
  return (
    <>
      <Table.Tr key={row.uuid}>
        <Table.Td>{row.company.tax_id}</Table.Td>
        <Table.Td>
          {row.company_additional_info ? row.company_additional_info : row.company.legal_name}
        </Table.Td>
        <Table.Td>{capitalizeFirstLetter(row.dte_type)}</Table.Td>
        <Table.Td>{row.number}</Table.Td>
        <Table.Td>{formatAmount(row.total_amount)}</Table.Td>
        <Table.Td>
          <BadgeWithHoverCard row={row} />
        </Table.Td>
        <Table.Td>{row.issue_date}</Table.Td>
        <Table.Td>
          {row.status === 'draft' ? (
            <DraftMenu row={row} canEmit={canEmit()} />
          ) : (
            <NormalMenu
              row={row}
              kind={kind}
              openNoPdfModal={openNoPdfModal}
              openCreditNoteModal={openCreditNoteModal}
              openAnswerDocumentModal={openAnswerDocumentModal}
              openResendPDFModal={openResendPDFModal}
              setAcceptDocument={setAcceptDocument}
            />
          )}
        </Table.Td>
      </Table.Tr>
      <VoidDocumentModal opened={openedCreditNoteModal} close={closeCreditNoteModal} row={row} />
      <NoPdfModal opened={openedNoPdfModal} close={closeNoPdfModal} />
      <AnswerDocumentModal
        opened={openedAnswerDocumentModal}
        close={closeAnswerDocumentModal}
        acceptDocument={acceptDocument}
        uuid={row.uuid}
      />
      <ResendPDFModal opened={openedResendPDFModal} close={closeResendPDFModal} row={row} />
      {/* <ConfirmationModal
        apiFunction={deleteDocument}
        opened={openedConfirmationModal}
        close={closeConfirmationModal}
      /> */}
    </>
  );
};

const SeeDocument = ({
  row,
  openNoPdfModal,
}: {
  row: CommonHash;
  openNoPdfModal: () => void;
}): React.ReactElement => {
  if (row.link === null && row.status === 'received') {
    return (
      <Menu.Item
        leftSection={<IconEye style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
        onClick={openNoPdfModal}
      >
        Ver documento
      </Menu.Item>
    );
  } else if (
    row.link === null &&
    row.status === 'sent' &&
    row.sii_status !== 'sii_rejected' &&
    row.sii_status !== 'company_received'
  ) {
    return (
      <Menu.Item
        leftSection={<Loader size="xs" type="dots" />}
        component="a"
        href={`${row.link}`}
        target="_blank"
      >
        Generando factura
      </Menu.Item>
    );
  } else if (row.link !== null) {
    return (
      <Menu.Item
        leftSection={<IconEye style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
        component="a"
        href={`${row.link}`}
        target="_blank"
      >
        Ver Documento
      </Menu.Item>
    );
  } else {
    return <></>;
  }
};

const DraftMenu = ({ row, canEmit }: { row: CommonHash; canEmit: boolean }) => {
  const { mutate: EmitDocument } = useEmitDocumentMutation();
  const { mutate: DeleteDocument } = useDeleteDocument();
  const [
    openedDeleteConfirmationModal,
    { open: openDeleteConfirmationModal, close: closeDeleteConfirmationModal },
  ] = useDisclosure(false);
  const [openedNoFoliosModal, { open: openNoFoliosModal, close: closeNoFoliosModal }] =
    useDisclosure(false);
  const [
    openedPrevisualizationModal,
    { open: openPrevisualizationModal, close: closePrevisualizationModal },
  ] = useDisclosure(false);
  return (
    <Menu transitionProps={{ transition: 'pop' }} withArrow position="bottom-end" withinPortal>
      <Menu.Target>
        <ActionIcon variant="subtle" color="gray">
          <IconDots style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={<IconEdit style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
          component={Link}
          to={`/${String(row.uuid)}/editar`}
        >
          Editar
        </Menu.Item>
        <Menu.Item
          leftSection={<IconFileCheck style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
          onClick={() => {
            if (canEmit) EmitDocument({ uuid: row.uuid });
            else {
              openNoFoliosModal();
            }
          }}
        >
          Emitir
        </Menu.Item>
        <Menu.Item
          leftSection={<IconEye style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
          onClick={openPrevisualizationModal}
        >
          Previsualizar
        </Menu.Item>
        <Menu.Item
          leftSection={<IconTrash style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
          onClick={openDeleteConfirmationModal}
        >
          Eliminar
        </Menu.Item>
      </Menu.Dropdown>
      <ConfirmationModal
        opened={openedDeleteConfirmationModal}
        close={closeDeleteConfirmationModal}
        apiFunction={() => DeleteDocument({ uuid: row.uuid })}
        id={row.uuid}
      />
      <NoFoliosModal
        opened={openedNoFoliosModal}
        close={closeNoFoliosModal}
        dteType={capitalizeFirstLetter(row.dte_type) || ''}
      />
      <DocumentPrevisualizationModal
        opened={openedPrevisualizationModal}
        close={closePrevisualizationModal}
        documentUuid={row.uuid}
      />
    </Menu>
  );
};

interface NormalMenuProps {
  row: CommonHash;
  kind: string;
  openNoPdfModal: () => void;
  openCreditNoteModal: () => void;
  openAnswerDocumentModal: () => void;
  openResendPDFModal: () => void;
  setAcceptDocument: React.Dispatch<React.SetStateAction<boolean>>;
}

const NormalMenu = ({
  row,
  kind,
  openNoPdfModal,
  openCreditNoteModal,
  openAnswerDocumentModal,
  openResendPDFModal,
  setAcceptDocument,
}: NormalMenuProps) => {
  const canEmitCreditNote = () => {
    if (kind === 'sent' && !['sii_waiting', 'sii_rejected', 'canceled'].includes(row.sii_status))
      return true;
    return false;
  };

  const canResendDocument = (): boolean => {
    return (
      row.raw_xml !== null &&
      row.status === 'sent' &&
      row.sii_status !== 'sii_waiting' &&
      row.sii_status !== 'sii_rejected'
    );
  };
  const downloadXml = (row: CommonHash) => {
    const element = document.createElement('a');
    const file = new Blob([row.raw_xml], { type: 'application/xml' });
    element.href = URL.createObjectURL(file);
    element.download = `${row.company.tax_id}_${row.number}.xml`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Menu transitionProps={{ transition: 'pop' }} withArrow position="bottom-end" withinPortal>
      <Menu.Target>
        <ActionIcon variant="subtle" color="gray">
          <IconDots style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <SeeDocument row={row} openNoPdfModal={openNoPdfModal} />
        {row.raw_xml && (
          <Menu.Item
            leftSection={<IconDownload style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
            onClick={() => downloadXml(row)}
          >
            Descargar XML
          </Menu.Item>
        )}
        {canResendDocument() && (
          <Menu.Item
            leftSection={<IconSend style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
            onClick={() => {
              openResendPDFModal();
            }}
          >
            Reenviar Documento
          </Menu.Item>
        )}
        {row.status === 'received' &&
          row.sii_status === 'company_received' &&
          row.can_answer_dte && (
            <>
              <Menu.Item
                leftSection={
                  <IconFileCheck style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                }
                color="green"
                onClick={() => {
                  setAcceptDocument(true);
                  openAnswerDocumentModal();
                }}
              >
                Aceptar
              </Menu.Item>
              <Menu.Item
                leftSection={<IconFileX style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                color="red"
                onClick={() => {
                  setAcceptDocument(false);
                  openAnswerDocumentModal();
                }}
              >
                Rechazar
              </Menu.Item>
            </>
          )}
        {canEmitCreditNote() && (
          <Menu.Item
            leftSection={<IconTrash style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
            color="red"
            onClick={openCreditNoteModal}
          >
            Anular Documento
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};
