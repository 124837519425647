import { useDisclosure } from '@mantine/hooks';
import { Container, Title, Group, Button, Text, Table, Paper, Skeleton, rem, Switch, HoverCard } from '@mantine/core';
import { useCafs, useAddCaf } from '@api/cafs';
import { useAuthorizedDocuments } from '@api/authorized_documents';
import { AuthorizedDocument, Caf } from '@types';
import DropzoneModal from '@components/DropzoneModal';
import StampFoliosModal from './StampFoliosModal';
import { useAuth } from '@providers/auth';
import { useUpdateAutoStampAccount } from '@api/accounts';

interface CafTableProps {
  cafs?: Caf[];
  loading: boolean;
  authorized_documents?: AuthorizedDocument;
}
function Cafs() {
  const { data: cafs, isLoading, isFetching } = useCafs();
  const { data: authorized_documents, isLoading: isLoadingAuthorized, isFetching: isFetchingAuthorized } = useAuthorizedDocuments();
  const [openedDropZonaModal, { open: openDropzoneModal, close: closeDropzoneModal }] =
    useDisclosure(false);
  const [openedStampFoliosModal, { open: openStampFoliosModal, close: closeStampFoliosModal }] =
    useDisclosure(false);
  const { mutate: AddCaf } = useAddCaf();
  const loading = isLoading || isFetching || cafs === undefined;
  const loadingAuthorized = isLoadingAuthorized || isFetchingAuthorized || authorized_documents === undefined;
  const { session } = useAuth();
  const { mutate: updateAutoStamp } = useUpdateAutoStampAccount()

  const onchangeSwitch = () => {
    updateAutoStamp({ data: { auto_stamp: !session?.activeAccount?.auto_stamp } });
  }
  return (
    <Container fluid>
      <Title order={2}>Documentos</Title>
      <Group align="center" mt={20} justify="space-between">
        <Group>
          <Button onClick={openDropzoneModal}>Cargar Folios</Button>
          <Button onClick={openStampFoliosModal} loading={loadingAuthorized}>Timbrar Folios</Button>
          {/* <Button>Solicitar Documentos</Button> */}
          {/* <Button>Actualizar información</Button> */}
        </Group>
        {/* <Button>Habilitar Documentos</Button> */}
        <Group>
          <HoverCard position="top">
            <HoverCard.Target>
              <Text c="dimmed" size="sm" fw={700} my={20}>
                TIMBRADO AUTOMÁTICO
              </Text>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Text size="sm">
                Activa timbrado de folios automático para los documentos habilitados.
              </Text>
            </HoverCard.Dropdown>
          </HoverCard>
          <Switch checked={session?.activeAccount?.auto_stamp} labelPosition="left" size="md"
            onChange={onchangeSwitch}
          />
        </Group>
      </Group>
      <Text c="dimmed" size="sm" fw={700} my={20}>
        DOCUMENTOS HABILITADOS
      </Text>
      <CafTable cafs={cafs?.data || []} loading={loading} />
      <StampFoliosModal
        opened={openedStampFoliosModal}
        close={closeStampFoliosModal}
        authorized_documents={authorized_documents?.data || []}
      />
      <DropzoneModal opened={openedDropZonaModal} close={closeDropzoneModal} mutate={AddCaf} />
    </Container>
  );
}

function CafTable({ cafs, loading }: CafTableProps) {
  let rows;
  if (loading) {
    rows = Array.from({ length: 5 }, (_, index) => (
      <Table.Tr key={index}>
        <Table.Td>
          <Skeleton visible={loading} height={rem(30)} />
        </Table.Td>
        <Table.Td>
          <Skeleton visible={loading} height={rem(30)} />
        </Table.Td>
        <Table.Td>
          <Skeleton visible={loading} height={rem(30)} />
        </Table.Td>
        <Table.Td>
          <Skeleton visible={loading} height={rem(30)} />
        </Table.Td>
        <Table.Td>
          <Skeleton visible={loading} height={rem(30)} />
        </Table.Td>
      </Table.Tr>
    ));
  } else {
    rows = cafs?.map((caf) => (
      <Table.Tr key={caf.id}>
        <Table.Td>
          <Skeleton visible={loading}>{caf.document}</Skeleton>
        </Table.Td>
        <Table.Td>
          <Skeleton visible={loading}>{caf.next_number}</Skeleton>
        </Table.Td>
        <Table.Td>
          <Skeleton visible={loading}>{caf.left_to_use}</Skeleton>
        </Table.Td>
        <Table.Td>
          <Skeleton visible={loading}>{caf.dte_type}</Skeleton>
        </Table.Td>
        <Table.Td>
          <Skeleton visible={loading}>{caf.last_request}</Skeleton>
        </Table.Td>
      </Table.Tr>
    ));
  }
  return (
    <Paper shadow="xs">
      <Table striped highlightOnHover withTableBorder verticalSpacing="xs">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>DOCUMENTO</Table.Th>
            <Table.Th>FOLIO SIGUIENTE</Table.Th>
            <Table.Th>FOLIOS DISPONIBLES</Table.Th>
            <Table.Th>CODIGO</Table.Th>
            <Table.Th>FECHA ÚLTIMA SOLICITUD</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Paper>
  );
}

export default Cafs;
