import {
  Container,
  Anchor,
  rem,
  Text,
  Group,
  Divider,
  Paper,
  Stack,
  Button,
  Skeleton,
} from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useCredential, useDeleteCredential } from '@api/credentials';
import UploadCredentialModal from './UploadCredentialModal';
import ConfirmationModal from '@components/ConfirmationModal/ConfirmationModal';
import Line from '@components/ConfigurationTable/Line';

function CredentialsIndex() {
  const { data: credential, isLoading: isCredentialLoading } = useCredential();
  const { mutate: deleteCredential } = useDeleteCredential();
  const [
    openedUploadCredentialModal,
    { open: openUploadCredentialModal, close: closeUploadCredentialModal },
  ] = useDisclosure(false);
  const [openedConfirmationModal, { open: openConfirmationModal, close: closeConfirmationModal }] =
    useDisclosure(false);
  return (
    <Container fluid>
      <Group>
        <Anchor
          href="/configuracion"
          underline="never"
          c="dimmed"
          className="d-flex align-items-center"
          fw={700}
        >
          <IconChevronLeft
            style={{
              width: rem(20),
              height: rem(20),
              color: 'var(--mantine-color-dimmed)',
              marginRight: 10,
            }}
          />
          Configuración
        </Anchor>
      </Group>
      <Text fz={28} fw={700}>
        Firma electrónica
      </Text>
      <Stack align="center" mt={50}>
        <Paper shadow="xs" px={15} py={20} withBorder>
          <Stack px={30} gap={0}>
            <Line name="RUT" value={credential?.data.tax_id} loading={isCredentialLoading} />
            <Divider my={10} />
            <Line name="Nombre" value={credential?.data.name} loading={isCredentialLoading} />
            <Divider my={10} />
            <Line name="Email" value={credential?.data.email} loading={isCredentialLoading} />
            <Divider my={10} />
            <Line
              name="Expiración"
              value={credential?.data.expired_date}
              loading={isCredentialLoading}
            />
            <Divider my={10} />
            <Line name="Emisor" value={credential?.data.emitter} loading={isCredentialLoading} />
          </Stack>
          <Skeleton visible={isCredentialLoading} px={30} mt={20}>
            <Group justify="flex-end">
              <Button onClick={openUploadCredentialModal}>Reemplazar</Button>
              <Button component="a" target="_blank" href="https://www.chilefirmas.cl/">
                Comprar nueva
              </Button>
              <Button onClick={openConfirmationModal}>Eliminar</Button>
            </Group>
          </Skeleton>
        </Paper>
      </Stack>
      <UploadCredentialModal
        opened={openedUploadCredentialModal}
        close={closeUploadCredentialModal}
      />
      <ConfirmationModal
        opened={openedConfirmationModal}
        close={closeConfirmationModal}
        apiFunction={deleteCredential}
        id={credential?.data?.id}
      />
    </Container>
  );
}

export default CredentialsIndex;
