import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useContext, useMemo } from 'react';
import { AuthContext } from '@providers/auth';
import Login from './session/Login';
import ForgottenPassword from './session/ForgottenPassword';
import RecoveryPassword from './session/RecoveryPassword';
import NewUser from './session/NewUser';
import ChangePassword from '@pages/authenticated/users/ChangePassword';
import Sent from '@pages/authenticated/documents/sent';
import FileImports from '@pages/authenticated/fileImports';
import FileImport from '@pages/authenticated/fileImports/show';
import Received from '@pages/authenticated/documents/received';
import Cafs from './authenticated/cafs';
import Configuration from '@pages/authenticated/configuration';
import ShowCertificate from '@pages/authenticated/configuration/credentials';
import FormDocument from '@pages/authenticated/documents/form';
import ShowLogo from '@pages/authenticated/configuration/logo/show';
import ShowBankingDetails from '@pages/authenticated/configuration/bankingDetails';
import UserTable from './authenticated/configuration/users';
import ConvertReceipt from '@pages/unauthenticated/convertReceipt';
import SuccessTransformation from './unauthenticated/convertReceipt/sucessTransformation';
import Layout from './Layout';

const Routes = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  const router = useMemo(() => {
    const publicRoutes = [
      {
        path: '/sample',
        element: <div>Sample Page</div>,
      },
      {
        path: '/convertir-boleta/:uuid',
        element: <ConvertReceipt />,
      },
      {
        path: '/convertir-boleta/exito',
        element: <SuccessTransformation />,
      },
    ];
    const authenticatedRoutes = [
      {
        path: '',
        element: <Layout />,
        children: [
          {
            index: true,
          },
          {
            path: 'logout',
            element: <div>Logout</div>,
          },
          {
            path: '/emitir',
            element: <FormDocument />,
          },
          {
            path: '/:id/editar',
            element: <FormDocument />,
          },
          {
            path: '/carga-masiva',
            element: <FileImports />,
          },
          {
            path: '/carga-masiva/:uuid',
            element: <FileImport />,
          },
          {
            path: '/emitidos',
            element: <Sent />,
          },
          {
            path: '/recibidos',
            element: <Received />,
          },
          {
            path: '/folios',
            element: <Cafs />,
          },
          {
            path: '/configuracion',
            element: <Configuration />,
          },
          {
            path: '/configuracion/firma-electronica',
            element: <ShowCertificate />,
          },
          {
            path: '/configuracion/logo',
            element: <ShowLogo />,
          },
          {
            path: '/configuracion/informacion-bancaria',
            element: <ShowBankingDetails />,
          },
          {
            path: '/configuracion/usuarios',
            element: <UserTable />,
          },
          {
            path: '/cambiar-contraseña',
            element: <ChangePassword />,
          },
        ],
      },
    ];

    const unauthenticatedRoutes = [
      {
        element: <div>Public Home</div>,
      },
      {
        path: 'login',
        index: true,
        element: <Login />,
      },
      {
        path: 'recuperar-contraseña',
        element: <ForgottenPassword />,
      },
      {
        path: 'recuperar-contraseña/:token',
        element: <RecoveryPassword />,
      },
      {
        path: 'nuevo-usuario/:token',
        element: <NewUser />,
      },
    ];
    const allRoutes = [
      {
        path: '/',
        children: [
          ...publicRoutes,
          ...(isLoggedIn ? [] : unauthenticatedRoutes),
          ...authenticatedRoutes,
          {
            path: '*',
            element: <Navigate to="/" replace={true} />,
          },
        ],
      },
    ];

    return sentryCreateBrowserRouter(allRoutes);
  }, [isLoggedIn]);
  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
