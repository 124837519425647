import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useSearchParams, useParams } from 'react-router-dom';
import { Title, Pagination, Group, Button, Paper, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useFileImportDocuments, useMassiveIssuance, useFileImport } from '@api/fileImports';
import { TableSort } from '@components/TableSort/TableSort';
import { useFilters } from '@providers/filters';
import FilterModal from '../documents/FilterModal';
import FilterButton from '@pages/authenticated/documents/FilterButton';
import { FileImport as FileImportType } from '@types';

const FileImport = () => {
  const { uuid } = useParams();
  const { filters } = useFilters();
  const [searchParams, setSearchParams] = useSearchParams();

  const initialPage = Number(searchParams.get('page')) || 1;
  const [activePage, setPage] = useState(initialPage);
  const [direction, setDirection] = useState('DESC');
  const [sortKey, setSortKey] = useState('issue_date');
  const startDate =
    filters.start_date instanceof Date ? dayjs(filters.start_date).format('YYYY-MM-DD') : null;
  const endDate =
    filters.end_date instanceof Date ? dayjs(filters.end_date).format('YYYY-MM-DD') : null;
  const { data: documents, isFetching } = useFileImportDocuments(
    uuid as string,
    startDate,
    endDate,
    filters.tax_id,
    filters.legal_name,
    filters.document_type,
    filters.status,
    activePage,
    sortKey,
    direction,
  );
  const { data: fileImport } = useFileImport(uuid as string);
  const { mutate: massiveIssuance, isLoading } = useMassiveIssuance();
  const [openedFilterModal, { open: openFilterModal, close: closeFilterModal }] =
    useDisclosure(false);

  useEffect(() => {
    if (activePage === 1) {
      searchParams.delete('page');
      setSearchParams(searchParams);
    } else {
      setSearchParams({ page: String(activePage) });
    }
  }, [activePage, setSearchParams, searchParams]);
  return (
    <>
      <Title order={1}>Carga Masiva DTEs</Title>
      <Group justify="space-between" align="center" mt={10} mb={20}>
        <FilterButton open={openFilterModal} />
        {fileImport?.data.status !== 'emitted' && (
          <Paper shadow="sm">
            <MassiveEmitButton
              fileImport={fileImport?.data}
              isLoading={isLoading}
              massiveIssuance={massiveIssuance}
              uuid={uuid}
            />
          </Paper>
        )}
      </Group>
      <TableSort
        docsProps={documents?.data}
        isFetching={isFetching}
        kind="received"
        sortKey={sortKey}
        direction={direction}
        setSortKey={setSortKey}
        setDirection={setDirection}
      />
      <FilterModal opened={openedFilterModal} close={closeFilterModal} />
      <div className="d-flex justify-content-center">
        <Pagination
          total={documents?.meta.pages || 1}
          value={activePage}
          onChange={setPage}
          mt="sm"
          pb={40}
        />
      </div>
    </>
  );
};

export default FileImport;

function MassiveEmitButton({
  fileImport,
  isLoading,
  massiveIssuance,
  uuid,
}: {
  fileImport?: FileImportType;
  isLoading: boolean;
  massiveIssuance: ({ uuid }: { uuid: string | undefined }) => void;
  uuid?: string;
}) {
  if (isLoading) {
    return (
      <Button disabled w={150}>
        Emitir todos
      </Button>
    );
  } else if (fileImport?.can_massive_emit.can_emit === false) {
    return (
      <Tooltip label={`No hay folios suficientes para ${fileImport.can_massive_emit.message}`}>
        <Button disabled w={150}>
          Emitir todos
        </Button>
      </Tooltip>
    );
  } else {
    return (
      <Button w={150} onClick={() => massiveIssuance({ uuid: uuid })}>
        Emitir todos
      </Button>
    );
  }
}
