import request from '@utils/axios-utils';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ApiObject, Account, ApiList, CommonHash } from '@types';
import { useAuth } from '@providers/auth';
import Notification from '@components/notification/notification';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFilters, defaultState } from '@providers/filters';

export function useUserActiveAccount() {
  const { session } = useAuth();
  return useQuery<ApiObject<Account>>(
    ['account'],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/users/active_account`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading',
    },
  );
}

export function useUserAccounts() {
  const { session } = useAuth();
  return useQuery<ApiList<Account>>(
    ['accounts'],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/users/accounts`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading',
    },
  );
}

export function useUpdateUserActiveAccount() {
  const queryClient = useQueryClient();
  const { session, setSession } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setFilters } = useFilters();
  return useMutation(
    ({ data }: CommonHash) => {
      return request({
        authorizationToken: session?.token,
        url: `/users/change_active_account`,
        method: 'PATCH',
        data,
      });
    },
    {
      onMutate: () => {
        const previousActiveAccount = session?.activeAccount;
        setSession({ status: 'loading', activeAccount: undefined });
        searchParams.delete('page');
        setSearchParams(searchParams);
        return { previousActiveAccount };
      },
      onSuccess: async ({ data }) => {
        setFilters(defaultState);

        const excludedQueryKey = 'document_details';
        const queries = queryClient.getQueryCache().getAll();

        await Promise.all(
          queries
            .filter((query) => query.queryKey[0] !== excludedQueryKey)
            .map((query) => queryClient.invalidateQueries(query.queryKey))
        );
        setSession({ status: 'loggedIn', activeAccount: data.data });
      },

      onError: (_err: any, _variables: any, context: any) => {
        setSession({ status: 'loggedIn', activeAccount: context.previousActiveAccount });
        Notification({
          message:
            'Error al cambiar la cuenta activa. Por favor, intenta de nuevo.\n',
          type: 'error',
        });
      },
    },
  );
}

export function useForgottenUserPassword() {
  const navigate = useNavigate();
  return useMutation(
    ({ email }: CommonHash) => {
      return request({
        url: `/users/password_recovery`,
        method: 'POST',
        data: { email },
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Revisa tu correo para recuperar tu contraseña' });
        navigate('/login');
      },
      onError: (error: any) => {
        Notification({ type: 'error', message: error?.response?.data?.error });
      },
    },
  );
}

export function useChangeUserPasswordByRecovery() {
  const navigate = useNavigate();
  return useMutation(
    ({ password, token }: CommonHash) => {
      return request({
        url: `/users/change_password`,
        method: 'PATCH',
        data: { password, token },
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Contraseña cambiada correctamente' });
        navigate('/login');
      },
      onError: (error: any) => {
        Notification({ type: 'error', message: error?.response?.data?.error });
      },
    },
  );
}

export function useNewUserPassword() {
  const navigate = useNavigate();
  return useMutation(
    ({ password, token }: CommonHash) => {
      return request({
        url: `/users/new_user`,
        method: 'PATCH',
        data: { password, token },
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Contraseña creada correctamente' });
        navigate('/login');
      },
      onError: (error: any) => {
        Notification({ type: 'error', message: error?.response?.data?.error });
      },
    },
  );
}

export function useUpdatePassword() {
  const { session } = useAuth();
  const navigate = useNavigate();
  return useMutation(
    ({ password, new_password }: CommonHash) => {
      return request({
        authorizationToken: session?.token,
        url: `/users/update_password`,
        method: 'PATCH',
        data: { password, new_password },
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Contraseña cambiada correctamente' });
        navigate('/');
      },
      onError: (error: any) => {
        Notification({ type: 'error', message: error?.response?.data?.error });
      },
    },
  );
}
