import {
  AppShell,
  Burger,
  Group,
  ScrollArea,
  NavLink,
  Select,
  Loader,
  Modal,
  Stack,
  Text,
  Skeleton,
  Menu,
  rem,
  Button,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Logo from '@assets/logo.png';
import {
  IconSettings,
  IconRubberStamp,
  IconPencilPlus,
  IconFiles,
  IconFileUpload,
  IconFileDownload,
  IconHomeEdit,
  IconUserCircle,
  IconLock,
  IconPower,
} from '@tabler/icons-react';
import { useAuth } from '@providers/auth';
import { Outlet, Link } from 'react-router-dom';
import { useLogout } from '@api/auth';
import { useNavbar } from '@providers/navbar';
import { useUserAccounts, useUpdateUserActiveAccount } from '@api/users';
import { User } from '@types';
import styles from './Template.module.scss';
import LogRocket from 'logrocket';
import Notification from '@components/notification/notification';
import { useState } from 'react';

interface SwitchingAccountsModalProps {
  opened: boolean;
  close: () => void;
}

function ItemButton(props: React.ComponentPropsWithoutRef<'button'>) {
  const { mutate: LogOut } = useLogout();

  return (
    <Button
      {...props}
      onClick={() => LogOut()}
      variant="transparent"
      fw={400}
      leftSection={<IconPower style={{ width: rem(14), height: rem(14) }} />}
      className={styles.logOutButton}
      styles={{
        inner: { justifyContent: 'start' },
      }}
    >
      Cerrar Sesión
    </Button>
  );
}
function UserMenu({ user }: { user?: User }) {
  return (
    <Menu trigger="click-hover">
      <Menu.Target>
        <Button variant="transparent">
          <IconUserCircle
            style={{ width: rem(28), height: rem(28) }}
            stroke={1.5}
            color="var(--mantine-primary-color-filled)"
          />
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{user?.email}</Menu.Label>
        <Menu.Item
          leftSection={<IconLock style={{ width: rem(14), height: rem(14) }} />}
          component={Link}
          to={'/cambiar-contraseña'}
        >
          Cambiar Contraseña
        </Menu.Item>
        <Menu.Item component={ItemButton} />
      </Menu.Dropdown>
    </Menu>
  );
}
export function Template() {
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const { opened, toggleNavbar } = useNavbar();
  const { session } = useAuth();
  const { data: accounts, isLoading: isAccountsLoading } = useUserAccounts();
  const [selectedAccount, setSelectedAccount] = useState(String(session?.activeAccount?.uuid));
  const {
    mutate: changeActiveAccount,
    isLoading: isUpdatingUserActiveAccount,
  } = useUpdateUserActiveAccount();
  const transformedAccounts = accounts?.data.map((account) => ({
    value: String(account.uuid),
    label: account.legal_name,
  }));

  const handleChangeOfActiveAccount = (value: string | null) => {
    if (value) {
      changeActiveAccount({ data: { account_id: value } });
    } else {
      Notification({ type: 'information', message: 'Ya estás en esta cuenta' });
    }
    setSelectedAccount(String(session?.activeAccount?.uuid))
  };
  if (import.meta.env.VITE_DEPLOY_ENV === 'main') {
    LogRocket.init('h2snb0/facturador');
    LogRocket.identify(session?.user?.id?.toString() || '', {
      email: session?.user?.email || '',
      account: session?.activeAccount?.legal_name || ''
    });
  }


  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 200,
        breakpoint: 'sm',
        collapsed: { mobile: mobileOpened, desktop: opened },
      }}
      padding="md"
    >
      <AppShell.Header>
        <Group h="100%" px="md" justify="space-between">
          <Group>
            <Burger opened={!mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
            <Burger
              opened={!opened}
              onClick={() => {
                toggleNavbar();
              }}
              visibleFrom="sm"
              size="sm"
            />
            <Link to="/">
              <img src={Logo} alt="logo" style={{ width: '146px' }} />
            </Link>
          </Group>
          <UserMenu user={session.user} />
        </Group>
      </AppShell.Header>
      <AppShell.Footer>Desarrollado por Plaglabs.com</AppShell.Footer>
      <AppShell.Navbar p="md">
        <AppShell.Section grow component={ScrollArea}>
          <Skeleton visible={isAccountsLoading}>
            <Select
              data={transformedAccounts}
              onChange={(e) => handleChangeOfActiveAccount(e)}
              disabled={isUpdatingUserActiveAccount}
              value={selectedAccount}
              leftSectionPointerEvents="none"
              leftSection={<IconHomeEdit size="1rem" stroke={1.5} />}
              styles={{
                input: {
                  border: 'none',
                },
                section: {
                  color: 'black',
                },
              }}
            />
          </Skeleton>
          <NavLink
            component={Link}
            to="/emitir"
            label="Emitir"
            leftSection={<IconPencilPlus size="1rem" stroke={1.5} />}
          />
          <NavLink
            component={Link}
            to="/carga-masiva"
            label="Carga Masiva"
            leftSection={<IconFiles size="1rem" stroke={1.5} />}
          />
          <NavLink
            component={Link}
            to="/emitidos"
            label="Emitidos"
            leftSection={<IconFileUpload size="1rem" stroke={1.5} />}
          />
          <NavLink
            component={Link}
            to="/recibidos"
            label="Recibidos"
            leftSection={<IconFileDownload size="1rem" stroke={1.5} />}
          />
          <NavLink
            component={Link}
            to="/folios"
            label="Timbrar Folios"
            leftSection={<IconRubberStamp size="1rem" stroke={1.5} />}
          />
          <NavLink
            component={Link}
            to="configuracion"
            label="Configuración"
            leftSection={<IconSettings size="1rem" stroke={1.5} />}
          />
        </AppShell.Section>
      </AppShell.Navbar>
      <AppShell.Main>
        <>
          <SwitchingAccountsModal opened={isUpdatingUserActiveAccount} close={() => { }} />
          <Outlet />
        </>
      </AppShell.Main>
    </AppShell>
  );
}

const SwitchingAccountsModal = ({ opened, close }: SwitchingAccountsModalProps) => {
  return (
    <Modal.Root
      size="sm"
      opened={opened}
      centered
      onClose={close}
      styles={{
        content: {
          maxWidth: '280px',
          height: '180px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <Modal.Overlay opacity={0.9} />
      <Modal.Content>
        <Modal.Body>
          <Stack justify="center" align="center" px={30} pb={10} gap={20}>
            <Loader color="var(--mantine-color-gray-5)" />
            <Text fz={14} c="var(--mantine-color-gray-5)">
              Cambiando de cuenta...
            </Text>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
