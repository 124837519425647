import request from '@utils/axios-utils';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { CommonHash, Credential, ApiObject } from '@types';
import Notification from '@components/notification/notification';
import { useAuth } from '@providers/auth';

export function useCredential() {
  const { session } = useAuth();
  return useQuery<ApiObject<Credential>>(
    'credential',
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/credential`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading',
    },
  );
}

export function useAddCredential() {
  const queryClient = useQueryClient();
  const { session } = useAuth();
  return useMutation(
    ({ data }: CommonHash) => {
      return request({
        authorizationToken: session?.token,
        url: `/credentials`,
        method: 'POST',
        data,
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Se ha agregado el certificado.' });
        queryClient.invalidateQueries('credential');
      },
    },
  );
}

export function useDeleteCredential() {
  const queryClient = useQueryClient();
  const { session } = useAuth();
  return useMutation(
    (id) => {
      return request({
        authorizationToken: session?.token,
        url: `/credentials/${id}`,
        method: 'DELETE',
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Se ha eliminado el certificado.' });
        queryClient.invalidateQueries('credential');
      },
    },
  );
}
