
import { useEditUserFromAccount } from '@api/accounts';
import { Modal, Button, Stack, TextInput, Switch } from '@mantine/core';
import { useForm } from '@mantine/form';
import { emailValidator } from '@pages/authenticated/documents/form/formValidators';
import { User } from '@types';
import { useEffect } from 'react';

function EditUserModal({ opened, close, user }: { opened: boolean, close: () => void, user: User | null }) {
  const { mutate: addUser } = useEditUserFromAccount()
  const form = useForm({
    initialValues: { id: user?.id, email: user?.email, admin: user?.admin },
    validate: {
      email: (value) => {
        if (!value) {
          return 'Debes ingresar un correo';
        }
        return emailValidator(value, false);
      },
    },
  });

  useEffect(() => {
    form.setValues({ id: user?.id, email: user?.email, admin: user?.admin });
  }, [user]);

  return (
    <Modal.Root size="md" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fz={20} fw={600}>
            Modifica la información del usuario
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={form.onSubmit((values, event) => {
              event?.preventDefault();
              event?.stopPropagation();
              addUser({ data: values });
              form.reset();
              close();
            })}
          >
            <Stack align="center">
              <TextInput
                label="email"
                placeholder={form.values.email || "Correo"}
                required
                value={form.values.email}
                error={form.errors.email}
                {...form.getInputProps('email')}
              />
              <Switch
                labelPosition="left"
                onLabel="SÍ" offLabel="NO"
                label="Este usuario es administrador?"
                checked={form.values.admin}
                onChange={(event) => form.setFieldValue('admin', event.currentTarget.checked)}
                size="md"
              />

              <Button type="submit">Actualizar</Button>
            </Stack>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}

export default EditUserModal;