import request from '@utils/axios-utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { ApiList, Document, ApiDetail, CommonHash, ApiObject } from '@types';
import { useAuth } from '@providers/auth';
import Notification from '@components/notification/notification';

const PER_PAGE = 10;

export function useDocuments(
  type: string,
  start_date: string | null,
  end_date: string | null,
  tax_id: string[],
  legal_name: string[],
  document_type: string[],
  status: string[],
  code: string | undefined,
  number: string | undefined,
  external_id: string | undefined,
  page: number,
  key: string,
  direction: string,
) {
  const { session } = useAuth();
  return useQuery<ApiList<Document>>(
    [
      'documents',
      start_date,
      end_date,
      tax_id,
      legal_name,
      document_type,
      status,
      code,
      number,
      external_id,
      page,
      key,
      direction,
    ],
    async () => {
      legal_name = legal_name.map((name) => encodeURIComponent(name));
      const response = await request({
        authorizationToken: session?.token,
        url: `/documents` +
          `?type=${type}` +
          `&start_date=${start_date}` +
          `&end_date=${end_date}` +
          `&tax_id=${[...tax_id, ...legal_name]}` +
          `&document_type=${document_type}` +
          `&status=${status}` +
          `&code=${code}` +
          `&number=${number}` +
          `&page=${page}` +
          `&page_size=${PER_PAGE}` +
          `&key=${key}` +
          `&direction=${direction}` +
          `&external_id=${external_id}`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading',
    },
  );
}
export function useDocument(uuid: string | undefined) {
  const { session } = useAuth();
  return useQuery<ApiObject<Document>>(
    ['document_details'],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/documents/${uuid}`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading' && uuid !== '' && uuid !== undefined,
      onSuccess: () => { },
    },
  );
}

export function useNewDocument(uuid: string | undefined) {
  const { session } = useAuth();
  const queryClient = useQueryClient();
  return useQuery<ApiObject<Document>>(
    ['new_document'],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/documents/new`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading' && uuid === undefined,
      onSuccess: () => {
        queryClient.invalidateQueries(['document_details']);
      },
    },
  );
}

export function useAnswerDocument() {
  const { session } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(
    ({ data }: CommonHash) => {
      return request({
        authorizationToken: session?.token,
        url: `/documents/${data['id']}/answer`,
        method: 'POST',
        data,
      });
    },
    {
      onSuccess: (data: CommonHash) => {
        if (data.request.status != 200) {
          Notification({ type: 'error', message: 'Error actualizando estado.' });
        } else {
          Notification({ type: 'success', message: 'Estado actualizado con éxito.' });
          queryClient.invalidateQueries(['documents']);
        }
      },
    },
  );
}

export function useVoidDocumentMutation() {
  const { session } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(
    ({ uuid, issueDate }: { uuid: string; issueDate: string }) => {
      return request({
        authorizationToken: session?.token,
        url: `/documents/${uuid}/void_document`,
        method: 'POST',
        data: {
          issue_date: issueDate,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['documents']);
        Notification({ type: 'success', message: 'Se ha generado el documento.' });
      },
      onError: (error: any) => {
        Notification({
          type: 'error', message: 'Error al generar el documento. '
            + error.response.data.error.message
        });
      }
    },
  );
}

export function useResendPDFMutation() {
  const { session } = useAuth();
  return useMutation(
    ({ uuid, contact_email }: { uuid: string; contact_email: string[] }) => {
      return request({
        authorizationToken: session?.token,
        url: `/documents/${uuid}/resend_pdf`,
        method: 'POST',
        data: {
          contact_email,
        },
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'information', message: 'El PDF llegará en un par de minutos' });
      },
    },
  );
}
export function useBookDocument(start_date: string, end_date: string, book_type: string) {
  const { session } = useAuth();
  return useQuery<string>(
    ['book', start_date, end_date],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/documents/generate_book?book_type=${book_type}&start_date=${start_date}&end_date=${end_date}`,
        method: 'GET',
      });
      const blob = new Blob([response.data], { type: 'text/plain' });
      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      const name = book_type == 'sales' ? 'LibroVentas' : 'LibroCompras';
      link.href = fileURL;
      link.setAttribute('download', `${name}_${start_date}_${end_date}.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      return fileURL;
    },
    {
      enabled: false,
    },
  );
}

export function useAddDocument() {
  const { session } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(
    ({ data }: CommonHash) => {
      return request({
        authorizationToken: session?.token,
        url: `/documents`,
        method: 'POST',
        data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('documents');
      },
      onError: () => {
        Notification({ type: 'error', message: 'Error al crear el documento.' });
      },
    },
  );
}

export function useDocumentReference(type: string, number: string) {
  const { session } = useAuth();
  return useQuery<ApiDetail<Document>>(
    ['document', type, number],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/documents/reference?&type=${type}&number=${number}`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading',
    },
  );
}

export function useEmitDocumentMutation() {
  const { session } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(
    ({ uuid }: { uuid: string }) => {
      return request({
        authorizationToken: session?.token,
        url: `/documents/${uuid}/emit`,
        method: 'PATCH',
      });
    },
    {
      onSuccess: () => {
        ['documents', 'file_imports', 'cafs'].forEach(key => queryClient.invalidateQueries([key]));
        Notification({ type: 'success', message: 'Se ha emitido el documento.' });
      },
      onError: (error: any) => {
        Notification({ type: 'error', message: 'Error al generar el documento. ' + error.response.data.error.message });
      },
    },
  );
}

export function useUpdateDocument() {
  const { session } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(
    ({ uuid, data }: { uuid: string; data: CommonHash }) => {
      return request({
        authorizationToken: session?.token,
        url: `/documents/${uuid}`,
        method: 'PATCH',
        data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['documents']);
        queryClient.invalidateQueries(['file_imports']);
        Notification({ type: 'success', message: 'Se ha actualizado el documento.' });
      },
      onError: () => {
        Notification({ type: 'error', message: 'Error al actualizar el documento.' });
      },
    },
  );
}

export function useDeleteDocument() {
  const { session } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(
    ({ uuid }: { uuid: string }) => {
      return request({
        authorizationToken: session?.token,
        url: `/documents/${uuid}`,
        method: 'DELETE',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['documents']);
        queryClient.invalidateQueries(['file_imports']);
        Notification({ type: 'success', message: 'Se ha eliminado el documento.' });
      },
    },
  );
}

export function useConvertReceipt() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ uuid, data }: { uuid: string; data: CommonHash }) => {
      return request({
        url: `/documents/${uuid}/convert_receipt`,
        method: 'POST',
        data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['documents']);
        Notification({
          type: 'success',
          message: 'Se ha mandando a generar la factura electrónica.',
        });
      },
      onError: () => {
        Notification({ type: 'error', message: 'Error al generar la factura.' });
      },
    },
  );
}

export function useDocumentUnauthenticated(uuid: string | undefined) {
  const { session } = useAuth();
  return useQuery<ApiObject<Document>>(
    ['document_details', uuid],
    async () => {
      const response = await request({
        url: `/documents/${uuid}/unauthenticated`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading' && uuid !== '' && uuid !== undefined,
      onSuccess: () => { },
      onError: (e) => {
        if (e instanceof AxiosError) {
          Notification({ type: 'error', message: e?.response?.data.message });
        }
      },
    },
  );
}
