import { useState } from 'react';
import { Modal, Stack, Text, MultiSelect, Group, Paper, Button, TextInput } from '@mantine/core';
import { useCompanies } from '@api/companies';
import { useSearchParams } from 'react-router-dom';

interface CompProps {
  opened: boolean;
  close: () => void;
}
import { useFilters, defaultState, Filters } from '@providers/filters';
import { DOCUMENTTYPEOPTIONSONFILTER } from '@constants';
import { DatePickerInput } from '@mantine/dates';

function FilterModal({ opened, close }: CompProps) {
  const { data: companies } = useCompanies();
  const { filters: storeFilters, setFilters } = useFilters();
  const [filters, setFilters_] = useState<Filters>(storeFilters);
  const [_searchParams, setSearchParams] = useSearchParams();
  const taxIdData = () => {
    const array = companies?.data?.map((company) => company.tax_id);
    return Array.from(new Set(array));
  };
  const legalNameData = () => {
    return companies?.data?.map((company) => ({ label: company.legal_name, value: company.tax_id }));
  };

  const clearFilters = () => {
    setFilters(defaultState);
    setFilters_(defaultState);
    setSearchParams({});
  };

  const applyFilters = () => {
    setFilters(filters);
    close();
    setSearchParams({});
  };

  return (
    <Modal.Root size="lg" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <Text fz={20} fw={600}>
              Filtros
            </Text>
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack>
            <Group justify="center" gap={60}>
              <DatePickerInput
                label="Fecha de emisión inicial"
                placeholder="Selecciona fecha"
                value={filters.start_date}
                valueFormat="YYYY-MM-DD"
                error={
                  filters.start_date && filters.end_date && filters.start_date > filters.end_date
                    ? 'Fecha inicial debe ser menor a la final'
                    : ''
                }
                onChange={(value) => {
                  setFilters_({ ...filters, start_date: value });
                }}
              />
              <DatePickerInput
                label="Fecha de emisión final"
                placeholder="Selecciona fecha"
                value={filters.end_date}
                valueFormat="YYYY-MM-DD"
                error={
                  filters.start_date && filters.end_date && filters.start_date > filters.end_date
                    ? 'Fecha final debe ser mayor a la inicial'
                    : ''
                }
                onChange={(value) => {
                  setFilters_({ ...filters, end_date: value });
                }}
              />
            </Group>
            <MultiSelect
              data={taxIdData()}
              searchable
              multiple
              label="RUT"
              nothingFoundMessage="Rut no encontrado..."
              onChange={(value) => {
                setFilters_({ ...filters, tax_id: value });
              }}
              value={filters.tax_id}
            />
            <MultiSelect
              data={legalNameData()}
              searchable
              multiple
              label="Razón Social"
              nothingFoundMessage="Razón Social no encontrada..."
              onChange={(value) => {
                setFilters_({ ...filters, tax_id: value });
              }}
              value={filters.tax_id}
            />
            <MultiSelect
              data={DOCUMENTTYPEOPTIONSONFILTER}
              searchable
              multiple
              label="Tipo documento"
              nothingFoundMessage="Tipo de documento no encontrado..."
              onChange={(value) => {
                setFilters_({ ...filters, document_type: value });
              }}
              value={filters.document_type}
            />
            <MultiSelect
              data={[
                { value: 'draft', label: 'Borrador' },
                { value: 'nil', label: 'Enviandose al SII' },
                { value: 'sii_waiting', label: 'Esperando respuesta SII' },
                { value: 'sii_rejected', label: 'Rechazado por SII' },
                { value: 'sii_accepted', label: 'Aceptado por SII' },
                {
                  value: 'sii_accepted_with_warnings',
                  label: 'Aceptado con observaciones por SII',
                },
                { value: 'company_not_received', label: 'No recibido por receptor' },
                { value: 'company_received', label: 'Recibido por receptor' },
                { value: 'company_accepted', label: 'Aceptado por receptor' },
                { value: 'company_rejected', label: 'Rechazado por receptor' },
                { value: 'canceled', label: 'Anulado' },
                { value: 'auto_accepted', label: 'Autoaceptado' },
              ]}
              searchable
              multiple
              label="Estado"
              nothingFoundMessage="Estado no encontrado..."
              onChange={(value) => {
                setFilters_({ ...filters, status: value });
              }}
              value={filters.status}
            />
            <TextInput
              label="Folio"
              placeholder="Folio"
              onChange={(event) => {
                setFilters_({ ...filters, number: event.currentTarget.value });
              }}
              value={filters.number}
            />
            <TextInput
              label="Código de Producto"
              placeholder="Código"
              onChange={(event) => {
                setFilters_({ ...filters, code: event.currentTarget.value });
              }}
              value={filters.code}
            />
            <TextInput
              label="Identificador externo"
              placeholder="Identificador externo"
              onChange={(event) => {
                setFilters_({ ...filters, external_id: event.currentTarget.value });
              }}
              value={filters.external_id}
            />
            <Group justify="center">
              <Paper shadow="sm">
                <Button className="btn-outline-primary" onClick={clearFilters}>
                  Limpiar filtros
                </Button>
              </Paper>
              <Paper shadow="sm">
                <Button onClick={applyFilters}>Aplicar filtros</Button>
              </Paper>
            </Group>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

export default FilterModal;
