import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Stack,
  Group,
  TextInput,
  Paper,
  Text,
  Button,
  Grid,
  Select,
  Checkbox,
  Tooltip,
  rem,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import { IconMinus, IconPlus, IconInfoCircle } from '@tabler/icons-react';
import styles from '@pages/authenticated/documents/Create.module.scss';
import { REFERENCEDOCUMENTSOPTIONS, NOTEREASONS } from '@constants';
import { noteType } from './formUtils';
import { useDocumentReference } from '@api/documents';
import { Reference } from '@types';

function References({ form }: { form: UseFormReturnType<any> }) {
  const initialReferencesAmount =
    form.values.document.references_attributes.filter(
      (referenceInstance: Reference) => !referenceInstance._destroy,
    ).length || 1;

  const [referencesAmount, setReferencesAmount] = useState(initialReferencesAmount);
  const references = Array.from({ length: referencesAmount }, (_, index) => {
    if (!form.values.document.references_attributes[index]._destroy)
      return <Line key={index} index={index} form={form} />;
  });
  const handleAddReference = () => {
    form.insertListItem('document.references_attributes', {
      original_number: '',
      original_dte_type: '',
      date: '',
      global_index: false,
      code: '',
      description: '',
      _destroy: false,
    });
    setReferencesAmount((prevValue: number) => prevValue + 1);
  };

  const handleRemoveReference = () => {
    if (form.values.document.references_attributes[referencesAmount - 1].id) {
      form.setFieldValue(`document.references_attributes.${referencesAmount - 1}._destroy`, true);
    } else {
      form.removeListItem('document.references_attributes', referencesAmount - 1);
    }
    setReferencesAmount((prevValue: number) => prevValue - 1);
  };

  useEffect(() => {
    setReferencesAmount(form.values.document.references_attributes.length);
  }, [form.values.document.references_attributes]);

  return (
    <Paper shadow="xl" p="lg">
      <Text fw={700} mb={10} fz={20}>
        Referencias
      </Text>
      <Stack>{references}</Stack>
      <Group justify="center" mt={10}>
        <Button className={styles.addBtn} onClick={handleAddReference}>
          <IconPlus />
        </Button>
        {canDeleteLine(referencesAmount, form.values.document.type) && (
          <Button className={styles.addBtn} onClick={handleRemoveReference} color="red">
            <IconMinus />
          </Button>
        )}
      </Group>
    </Paper>
  );
}

function Line({ index, form }: { index: number; form: UseFormReturnType<any> }) {
  const reference = form.values.document.references_attributes[index];
  const { data: referenceDocument } = useDocumentReference(
    reference.original_dte_type,
    reference.original_number,
  );
  const isNoteType = noteType(form.values.document.type);
  const [globalIndexValue, setGlobalIndexValue] = useState(false);
  useEffect(() => {
    if (!globalIndexValue) {
      form.clearFieldError(`document.references_attributes.${index}.description`);
    } else {
      form.setFieldValue(`document.references_attributes.${index}.original_number`, '');
      form.clearFieldError(`document.references_attributes.${index}.original_number`);
    }
  }, [globalIndexValue]);

  useEffect(() => {
    if (referenceDocument) {
      form.setFieldValue(
        `document.references_attributes.${index}.date`,
        dayjs(referenceDocument.data.issue_date).toDate(),
      );
      form.setFieldValue(
        `document.references_attributes.${index}.original_document_id`,
        referenceDocument.data.uuid,
      );
      if (referenceDocument.data.withholding_tax_percentage != 0) {
        form.setFieldValue(
          'document.withholding_tax_percentage',
          referenceDocument.data.withholding_tax_percentage,
        );
      }
      // console.log('FOOORM', form.values);
      // console.log('fooorm 2', form.getValues());
      // form.setValues((prevValues: any) => ({
      //   ...form.values,
      //   ...buildReferencedDocument(form.values, referenceDocument.data),
      // }));
      // console.log('justo dps', form.values.document);
    }
  }, [reference.original_dte_type, reference.original_number, referenceDocument]);
  return (
    <Grid>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <Select
          withAsterisk
          label="Tipo de documento"
          placeholder="Tipo de documento"
          searchable
          data={REFERENCEDOCUMENTSOPTIONS}
          {...form.getInputProps(`document.references_attributes.${index}.original_dte_type`)}
          value={form.values.document.references_attributes[index].original_dte_type}
          onChange={(value) =>
            form.setFieldValue(`document.references_attributes.${index}.original_dte_type`, value)
          }
        />
      </Grid.Col>
      {isNoteType && (
        <Grid.Col span={{ base: 0.6 }}>
          <Text
            fw={500}
            mt={2}
            style={{
              fontSize: 'var(--input-label-size, var(--mantine-font-size-sm))',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Ind
            <Tooltip label="Documento afecta a un número de más de 20 documentos del mismo tipo del documento de referencia">
              <IconInfoCircle
                style={{ width: rem(16), height: rem(16), color: 'var(--mantine-color-grey-4)' }}
              />
            </Tooltip>
          </Text>
          <Checkbox
            {...form.getInputProps(`document.references_attributes.${index}.global_index`)}
            mt={5}
            size="md"
            checked={globalIndexValue}
            onChange={(event) => {
              form.setFieldValue(
                `document.references_attributes.${index}.global_index`,
                event.currentTarget.checked,
              );
              setGlobalIndexValue(!globalIndexValue);
            }}
          />
        </Grid.Col>
      )}
      <Grid.Col span={isNoteType ? { base: 11.4, md: 1.4 } : { base: 12, md: 4 }}>
        <TextInput
          withAsterisk={!globalIndexValue}
          label="Folio"
          placeholder="Folio"
          disabled={form.values.document.references_attributes[index].global_index}
          {...form.getInputProps(`document.references_attributes.${index}.original_number`)}
        />
      </Grid.Col>
      <Grid.Col span={isNoteType ? { base: 12, md: 2 } : { base: 12, md: 4 }}>
        <DateInput
          withAsterisk
          valueFormat="YYYY-MM-DD"
          label="Fecha del documento"
          placeholder="Fecha"
          {...form.getInputProps(`document.references_attributes.${index}.date`)}
        />
      </Grid.Col>
      {noteType(form.values.document.type) && <AdditionalFields index={index} form={form} />}
    </Grid>
  );
}

function AdditionalFields({ index, form }: { index: number; form: UseFormReturnType<any> }) {
  return (
    <Grid.Col span={{ base: 12, md: 4 }}>
      <Select
        withAsterisk
        label="Razón Referencia"
        placeholder="Razón Referencia"
        data={NOTEREASONS}
        {...form.getInputProps(`document.references_attributes.${index}.code`)}
        value={form.values.document.references_attributes[index].code}
        onChange={(value) =>
          form.setFieldValue(`document.references_attributes.${index}.code`, value)
        }
      />
      <TextInput
        placeholder="Descripción adicional"
        mt={5}
        {...form.getInputProps(`document.references_attributes.${index}.description`)}
      />
    </Grid.Col>
  );
}

function canDeleteLine(referenceAmount: number, dteType: string) {
  if (noteType(dteType) && referenceAmount < 2) return false;
  if (referenceAmount < 1) return false;
  return true;
}

export default References;
