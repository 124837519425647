import { Table, Title, Group, Skeleton, Button, Stack, Center } from "@mantine/core";
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAccountUsers, useDeleteUserFromAccount } from "@api/accounts";
import { useDisclosure } from '@mantine/hooks';
import AddUserModal from "./addUserModal";
import EditUserModal from "./editUserModal";
import { User } from '@types';

const UserTable = () => {
  const { data: users, isFetching } = useAccountUsers();
  const { mutate: deleteUserMutation } = useDeleteUserFromAccount();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = Number(searchParams.get('page')) || 1;
  const [activePage] = useState(initialPage);
  const [openedAddUserModal, { open: openAddUserModal, close: closeAddUserModal }] =
    useDisclosure(false);
  const [openedEditUserModal, { open: openEditUserModal, close: closeEditUserModal }] =
    useDisclosure(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);


  const rows = users?.data.map((user) => {
    return (
      <Table.Tr key={user.id}>
        <Table.Td>{user.email}</Table.Td>
        <Table.Td>
          <Group justify="center">
            <Button variant="light" color="blue" radius="xl" size="xs"
              onClick={() => {
                setSelectedUser(user);
                openEditUserModal();
              }}>
              Editar
            </Button>
            <Button variant="light" color="red" radius="xl" size="xs"
              onClick={() => deleteUserMutation({ data: { id: user.id } })}>
              Eliminar
            </Button>
          </Group>
        </Table.Td>

      </Table.Tr >
    )
  });


  useEffect(() => {
    if (activePage === 1) {
      searchParams.delete('page');
      setSearchParams(searchParams);
    } else {
      setSearchParams({ page: String(activePage) });
    }
  }, [activePage, setSearchParams, searchParams]);

  return (
    <>
      <Title order={1}>Usuarios</Title>
      <Stack mah={300} maw={400} mx="auto">
        <Group justify="flex-end" mt={10} mb={20}>
          <Button onClick={openAddUserModal}>
            Añadir usuario
          </Button>
        </Group>
        <Table horizontalSpacing="md" verticalSpacing="md" highlightOnHover withTableBorder>
          <Table.Thead>
            <Table.Tr>
              <Table.Th >Correo Electronico</Table.Th>
              <Table.Th > <Center>Acciones</Center></Table.Th>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {isFetching ? (
              <Skeleton height={40} />
            ) : (
              (rows ?? []).length > 0 ? (
                rows
              ) : (
                <Table.Tr>
                  <Table.Td >
                    No otros usuarios asociados a esta cuenta
                  </Table.Td>
                </Table.Tr>
              )
            )}
          </Table.Tbody>
        </Table >
      </Stack >
      <AddUserModal
        opened={openedAddUserModal}
        close={closeAddUserModal}
      />
      <EditUserModal
        opened={openedEditUserModal}
        close={closeEditUserModal}
        user={selectedUser}
      />
    </>
  );
};


export default UserTable;